<template>
  <v-data-table
    class="ui-data-table loader-fullscreen"
    :class="getClass()"
    :style="cssVars"
    hide-default-footer
    v-bind="$attrs"
    v-on="omitListeners"
    :headers="filteredHeaders"
    :items="items"
    :no-data-text="$t('base.noData')"
    :header-props="headerProps"
    :options="options"
    :loading="loading"
    :show-select="showSelect"
    @update:sort-by="onUpdateSortBy"
    @update:sort-desc="onUpdateSortDesc"
    @item-selected="handleItemSelection"
  >
    <template v-slot:top="{ options }" v-if="!hideTop">
      <div v-if="title" class="pt-7 px-6 pb-2 font-weight-bold text-h2 black--text text--darken-6">
        {{ title }}
      </div>
      <v-container fluid class="py-5 px-6">
        <v-row dense class="flex-wrap flex-container justify-space-between">
          <v-col
            v-if="$slots.prependInner"
            cols="12"
            class="d-flex justify-space-between"
            style="flex: 1 0 auto"
          >
            <slot name="prependInner" />
          </v-col>

          <v-col v-if="$slots.title" cols="12">
            <slot name="title" />
          </v-col>

          <div v-if="$slots.filters" class="filters-custom-columns" :class="{ 'w-100': fullWidth }">
            <slot name="filters" />
          </div>
          <div v-if="$slots.filterInList" class="w-100">
            <slot name="filterInList" />
          </div>

          <div
            class="d-flex after-search justify-space-between"
            :class="{ 'custom-flex-wrap': flexWrap }"
          >
            <div class="d-flex" :class="{ 'filters-wrap': filtersWrap }">
              <slot name="emptyBlock" />
              <v-text-field
                class="search-table mr-3"
                v-if="!hideSearch"
                :value="searchField"
                background-color="black lighten-7"
                filled
                dense
                solo
                flat
                prepend-inner-icon="mdi-magnify"
                hide-details
                clearable
                :placeholder="$t('base.search')"
                @input="onInputSearchQuery"
              />
              <slot name="afterSearch" />
            </div>

            <div class="d-flex align-self-start search-select-wrapper justify-end">
              <span class="limit-pages">{{ $t('base.onPage') }}</span>
              <v-select
                v-model="options.pagination.limit"
                :items="itemsLimit"
                dense
                filled
                outlined
                hide-details="auto"
                append-icon="mdi-chevron-down"
                background-color="black lighten-7"
                @change="onChangePaginationLimit(options)"
                class="px-2 search-select"
              />
              <div class="limit-pages">
                <span class="limit-pages pr-8">{{ $t('base.paginationFrom') }} {{ options.total }}</span>
              </div>
            </div>

            <ui-pagination
              v-model="options.pagination"
              :total-items="options.totalItems"
              @input="
                $emit('update:options', { ...options, page: options.pagination.page })
                $emit('reFetchData')
              "
            />
            <div v-if="isBtnAction" class="ml-3">
              <slot name="action" />
            </div>
          </div>
        </v-row>
      </v-container>

      <ui-table-actions
        v-if="showTableActions"
        :selected-count="displaySelectedCount"
        @close="unselectAll"
      >
        <slot name="actions" />
      </ui-table-actions>
    </template>

    <template v-for="(header, index) in headers" v-slot:[`header.${header.value}`]>
      <span v-if="'tooltip' in header" :key="index"
      >{{header.text}} <v-icon size="14" v-tooltip="header.tooltip">mdi-information-slab-circle-outline</v-icon>
      </span>
      <span :key="index" v-else>{{header.text}}</span>
    </template>
    <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item, value }">
      <router-link
        class="slot-link"
        v-if="editPageName && (item?.id || item?.objectId)"
        :key="index"
        :to="{name: editPageName, params: {id: item.id || item.objectId}}"
        @click.native.stop="handleClick($event, {name: editPageName, params: {id: item.id || item.objectId}})"
      >
        {{ value }}
      </router-link>
      <div :key="index" v-else>{{ value }}</div>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <router-link
        class="slot-link"
        v-if="editPageName && scope?.item?.id"
        :key="slot"
        :to="{name: editPageName, params: {id: scope.item.id}}"
        @click.native.stop="handleClick($event, {name: editPageName, params: {id: scope.item.id}})"
      >
        <slot :name="slot" v-bind="scope" />
      </router-link>
      <slot v-else :name="slot" v-bind="scope" />
    </template>

    <template #header.data-table-select="{ on }">
      <v-popover
        placement="top-start"
        container=".ui-data-table"
        trigger="manual"
        :open="hasSomeSelected"
        :auto-hide="false"
      >
        <v-simple-checkbox
          :value="isAllSelected"
          :indeterminate="isSomeSelected"
          color="primary"
          v-on="on"
          :ripple="false"
          class="ui-table-checkbox"
          :class="{ 'ui-table-checkbox--indeterminate': isSomeSelected }"
          @input="onSelectionToggle"
        />

        <template #popover>
          <ui-card class="px-4 py-1">
            <div class="d-flex">
              <div class="text-body-1">
                {{ $t('base.selected') }}
                <span class="primary--text">{{ displaySelectedCount }}</span>
                {{ $t('base.items') }}.
              </div>

              <div
                class="primary--text text-body-1 font-weight-bold mx-2 cursor-pointer"
                @click="selectAll"
              >
                {{ $t('base.selectAll') }} ({{ totalItems }})
              </div>

              <div
                class="primary--text text-body-1 font-weight-bold cursor-pointer"
                @click="unselectAll"
              >
                {{ $t('base.clearAll') }}
              </div>
            </div>
          </ui-card>
        </template>
      </v-popover>
    </template>

    <template v-slot:item.data-table-select="{ item, select }">
      <ui-checkbox :value="isSelected(item)" @input="select($event)" @click.stop />
    </template>
  </v-data-table>
</template>

<script>
import { debounce, omit } from 'lodash'
import UiTableActions from '@/components/ui/framework/general/UITableActions.vue'

export default {
  name: 'ui-date-table',
  components: {
    UiTableActions,
  },
  props: {
    itemsLimit: {
      type: Array,
      default: () => {
        return [10, 20, 50, 100, 500, 1000]
      },
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMultiTable: {
      type: Boolean,
      default: false,
    },
    hideTop: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    selectionExclusive: {
      type: Boolean,
      default: false,
    },
    // function for disable possibility to select item
    selectableCondition: {
      type: Function,
      default: null,
    },
    selectableKey: {
      type: String,
      default: 'id',
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    isBtnAction: {
      type: Boolean,
      default: false,
    },
    noneBoxShadow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    headerProps: {
      type: Object,
      default: () => ({
        // for default desc sort icon must be mdi-chevron-down
        sortIcon: 'mdi-chevron-up',
      }),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    flexWrap: {
      type: Boolean,
      default: false,
    },
    filtersWrap: {
      type: Boolean,
      default: false,
    },
    editPageName: { // page to navigate to w/ ctrl pushed
      type: [String, Boolean],
      default: false
    },
    customHeight: {
      type: String,
      default: '0'
    },
  },
  data() {
    return {
      searchField: '',
      filteredHeaders: [],
      isSortEmitted: false,
    }
  },
  computed: {
    cssVars() {
      return {
        '--table-height': `calc(100vh - 200px - ${this.customHeight}px)`,
      }
    },
    itemsWithEnabledSelection() {
      return this.items.filter(item => !this.checkIfItemSelectionDisabled(item))
    },
    isPageItemsSelected() {
      return this.itemsWithEnabledSelection.every(item =>
        this.selectionExclusive ? !this.checkItemSelected(item) : this.checkItemSelected(item),
      )
    },
    isAllSelected() {
      return (
        (this.selectionExclusive && this.selectedItemsCount === 0) ||
        (this.selectedItemsCount === this.totalItems && this.totalItems > 0)
      )
    },
    selectedItemsCount() {
      return this.selectedItems.length
    },
    displaySelectedCount() {
      if(!this.totalItems) return 0
      return !this.selectionExclusive ?
        this.selectedItemsCount :
        this.totalItems - this.selectedItemsCount
    },
    hasSomeSelected() {
      return this.selectedItemsCount !== 0 || this.selectionExclusive
    },
    isSomeSelected() {
      return this.hasSomeSelected && !this.isAllSelected
    },
    showTableActions() {
      return this.hasSomeSelected && this.showSelect && this.$scopedSlots.actions
    },
    omitListeners() {
      return omit(this.$listeners, ['update:sort-by', 'update:sort-desc'])
    },
  },
  watch: {
    headers() {
      this.setHeaders(this.columnsToShow)
    },
    columnsToShow(data) {
      this.setHeaders(data)
    },
    'options.sortBy': {
      handler() {
        this.isSortEmitted = false
      },
    },
    'options.sortDesc': {
      handler() {
        this.isSortEmitted = false
      },
    },
  },
  created() {
    this.setHeaders(this.columnsToShow)
    this.searchField = this.options.search
  },
  methods: {
    onInputSearchQuery: debounce(function (query) {
      this.$emit('update:search', query)
    }, 300),
    setHeaders(selectedHeaders = []) {
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...this.headers]
        return
      }

      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)
      this.filteredHeaders = this.headers.filter(item => {
        return !this.isMultiTable ? colIDs.includes(item.value) : !colIDs.includes(item.value)
      })
    },
    getClass() {
      return [
        { 'ui-data-table-clickable': this.$listeners['click:row'] },
        { 'ui-loading': this.loading },
        { 'ui-box-shadow': this.noneBoxShadow },
      ]
    },
    onChangePaginationLimit(options) {
      this.$emit('update:options', {
        ...options,
        pagination: {
          ...options.pagination,
          page: 1,
        },
        page: 1,
        itemsPerPage: options.pagination.limit,
      })
      this.$emit('reFetchData')
    },
    onSelectionToggle() {
      if (this.isPageItemsSelected) {
        if (this.selectionExclusive) {
          return this.handleCurrentPageSelection(this.items)
        }
        this.handleCurrentPageDeselection(this.items)
      } else {
        if (this.selectionExclusive) {
          return this.handleCurrentPageDeselection(this.items)
        }
        this.handleCurrentPageSelection(this.items)
      }
    },
    checkIfItemSelectionDisabled(item) {
      return typeof this.selectableCondition === 'function' && !this.selectableCondition(item)
    },
    findItemIndexInSelected(item) {
      return this.selectedItems.findIndex(
        selectedItem => selectedItem[this.selectableKey] === item[this.selectableKey],
      )
    },
    checkItemSelected(item) {
      const index = this.findItemIndexInSelected(item)
      return index !== -1
    },
    handleCurrentPageSelection(items) {
      const selectedItems = this.selectedItems
      const itemsToAdd = items.filter(
        item => !this.checkItemSelected(item) && !this.checkIfItemSelectionDisabled(item),
      )

      this.updateSelected([...selectedItems, ...itemsToAdd])
    },
    handleCurrentPageDeselection(items) {
      this.updateSelected(
        this.selectedItems.filter(item => {
          return !items.find(
            unselectedItem => unselectedItem[this.selectableKey] === item[this.selectableKey],
          )
        }),
      )
    },
    updateSelected(updatedSelectedItems) {
      this.$emit('update:selected-items', updatedSelectedItems)
    },
    selectAll() {
      this.resetSelection()
      this.setSelectedAll(true)
    },
    unselectAll() {
      this.resetSelection()
      this.setSelectedAll(false)
    },
    resetSelection() {
      this.updateSelected([])
    },
    setSelectedAll(value = false) {
      this.$emit('update:selection-exclusive', value)
    },
    handleItemSelection({ item }) {
      const indexInSelected = this.findItemIndexInSelected(item)
      const updatedSelectedItems =
        indexInSelected === -1
          ? [item, ...this.selectedItems]
          : this.selectedItems.filter((item, itemIndex) => itemIndex !== indexInSelected)

      this.updateSelected(updatedSelectedItems)
    },
    isSelected(item) {
      return this.selectionExclusive ? !this.checkItemSelected(item) : this.checkItemSelected(item)
    },
    onUpdateSortBy() {
      if (this.isSortEmitted) return
      this.isSortEmitted = true
      this.$emit('update:sort-by')
    },
    onUpdateSortDesc() {
      if (this.isSortEmitted) return
      this.isSortEmitted = true
      this.$emit('update:sort-desc')
    },
    handleClick(event, route) {
      if (event.ctrlKey || event.metaKey) return;
      this.$router.push(route);
    }
  },
}
</script>

<style lang="scss">
.filters-custom-columns {
  flex: 20 0 auto;
}
.after-search {
  max-width: none;
  flex: 1 0 auto;
}
.filters-wrap {
  flex-wrap: wrap;
  flex-direction: column;
}
.after-search.custom-flex-wrap {
  flex-wrap: wrap;
  width: fit-content;
  row-gap: 10px;
  .limit-pages {
    padding-right: 0 !important;
  }
}
.flex-container {
  gap: 15px;
  align-items: center;
}
.v-data-table__wrapper {
  overflow-y: scroll;
  max-height: calc(var(--table-height));

  thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 3;
  }

  table > tbody + td {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 3;
  }
}

.ui-data-table {
  // pos: relative must have for correct the "select all" popover position
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 1px 10px rgba(188, 188, 188, 0.12), 0px 4px 5px rgba(188, 188, 188, 0.14),
    0px 2px 4px -1px rgba(188, 188, 188, 0.2);
  .ui-box-shadow {
    box-shadow: none;
  }
  .v-data-table__wrapper > table > thead > tr:last-child > th {
    white-space: nowrap;
  }

  .v-data-table__expand-icon {
    color: var(--v-primary-base);
  }

  .v-data-table__wrapper {
    //overflow-x: inherit;
    overflow-y: inherit;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--v-black-darken3);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f3f3f3;
    }

    scrollbar-color: var(--v-black-darken3) #f3f3f3;
    scrollbar-width: thin;
  }

  thead > tr > th > span {
    color: var(--v-black-lighten2);
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  tbody > tr {
    td {
      color: var(--v-black-darken3);
      font-weight: 400;
      font-size: 12px !important;
      line-height: 20px;
      height: 40px !important;
    }
  }

  thead > tr:last-child > th {
    border-bottom: 1px var(--v-black-lighten6) solid !important;
  }

  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: white !important;
    transform: scale(1, 1);
  }
  .v-data-table__wrapper
    tbody
    > tr:last-child:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    box-shadow: none !important;
    background: transparent !important;
  }

  tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 1px var(--v-black-lighten6) solid !important;
  }

  .search-table {
    .mdi {
      font-size: 16px;
      color: var(--v-black-lighten2);
    }

    .v-input__slot,
    .v-input__control {
      border-radius: 8px;
      min-height: 36px !important;
      max-width: 240px;
    }

    .v-text-field__slot {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      input {
        color: var(--v-black-darken3);
        padding-bottom: 4px;
      }

      input::placeholder {
        color: var(--v-black-lighten2) !important;
      }
    }
  }

  .search-select-wrapper {
    margin-left: auto;
    align-items: center;

    .limit-pages {
      font-size: 12px !important;
      font-weight: 400;
      line-height: 12px !important;
      letter-spacing: 0em;
      text-align: left;
      color: var(--v-black-darken1);
    }
  }

  .search-select {
    width: 74px;
    max-height: 30px;
    border-radius: 8px;
    z-index: 80;
    fieldset,
    .v-text-field .v-input__control {
      border-color: var(--v-black-lighten6);
    }
    .v-select__slot {
      .v-label {
        font-size: 14px !important;
        color: var(--v-black-darken6);
      }
      .v-select__selections {
        font-size: 12px;
        color: var(--v-black-darken6);

        .v-chip {
          margin-top: 5px;
        }
      }
      .v-icon {
        font-size: 20px;
        color: var(--v-black-lighten2);
      }
    }
    .v-input__control {
      .v-select__selections {
        height: 30px;

        .v-select__selection--comma {
          margin-right: 0;
          color: var(--v-black-darken6) !important;
        }
      }

      .v-input__slot {
        padding: 0 8px !important;
        min-height: 30px !important;

        .v-input__append-inner {
          margin-top: 6px;
          padding-left: 0;

          .v-input__icon {
            height: 18px;

            &--append {
              min-width: 18px;
              width: 18px;
            }
          }
        }

        .v-icon {
          font-size: 16px;
        }
      }
    }
  }

  .search-field {
    width: 220px;
  }
  .nested-table {
    .v-data-table-header tr th {
      height: 0;
      overflow: hidden;
      line-height: 0;
      max-height: 0;
      border-bottom-width: 0 !important;
    }
  }
}
.ui-data-table-clickable {
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.ui-loading {
  th.sortable,
  th.sortable i {
    pointer-events: none !important;
  }
}

.ui-table-checkbox {
  .v-input--selection-controls__input {
    height: 16px;
    width: 16px;
  }

  .v-icon {
    font-size: 20px;
    color: var(--v-black-lighten3);
  }

  &--indeterminate {
    .v-icon {
      color: var(--v-black-darken2);
    }
  }
}
.slot-link {
  color: #525252!important;
  text-decoration: none;
}
</style>
